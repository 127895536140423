import React, { useContext, useEffect, useState } from "react";
import { node_image_url, url } from "../../helpers";
import { toast } from "react-toastify";
import { userContext } from "../../context/UserContext";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";

function VendorRestaurantProfileDetail() {
  const { setLoad } = useContext(userContext);

  const [resname, setResName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useContext(userContext);
  const [vendorData, setVendorData] = useState([]);
  const [coverPicture, setCoverPicture] = useState("");
  const [coverPictureSrc, setCoverPictureSrc] = useState("");

  async function createRestaurant(e) {
    e.preventDefault();
    setLoad(true);
    const send_data = {
      data: {
        docstatus: 0,
        res_name: resname,
        res_phone: phone,
        res_email: email,
        res_address: address,
        delivery_charge: "1",
        doctype: "Restaurant",
        restaurant_description: description,
      },
    };

    let api_url = "";

    if (coverPicture) {
      const formData = new FormData();
      formData.append("image", coverPicture, coverPicture.name);

      const cover_response = await fetch(node_image_url + "/upload-files-bg", {
        method: "POST",
        body: formData,
      });

      if (cover_response.ok == true) {
        setLoad(false);
        const cover_data = await cover_response.json();
        if (cover_data?.status == 200) {
          send_data.data.cover_picture = node_image_url + cover_data?.file_url;
        }
      }
    }

    if (vendorData.length > 0) {
      api_url = `resource/Restaurant/${vendorData[0]?.name}`;
    } else {
      api_url = "resource/Restaurant";
    }

    const response = await fetch(url + api_url, {
      method: vendorData?.length > 0 ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
      },
      body: JSON.stringify(send_data),
    });

    if (response.ok == true) {
      const data = await response.json();
      if (data?.data?.name) {
        setLoad(false);
        toast.success("Restaurant information Udated Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 700);
      }
    } else {
      setLoad(false);
      toast.error("Internal server error!");
    }
  }

  useEffect(() => {
    setResName(user?.full_name);
    setEmail(user?.message?.email);
    // setPhone(data?.data[0]?.res_phone);
  }, [user])

  useEffect(() => {
    async function fetchData() {
      setLoad(true);
      const response = await fetch(
        url +
        'resource/Restaurant?fields=["*"]&limit_page_length=20&limit_start=0',
        {
          headers: {
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
        }
      );

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();

        if (data?.data?.length > 0) {
          setVendorData(data?.data);
          setResName(data?.data[0]?.res_name);
          setEmail(data?.data[0]?.res_email);
          setPhone(data?.data[0]?.res_phone);
          setAddress(data?.data[0]?.res_address);
          setDescription(data?.data[0]?.restaurant_description);
        }
      } else {
        setLoad(false);
        toast.error("Internal Server Error");
      }
    }

    fetchData();
  }, []);

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    {user?.role_profile_name == 'huserprofile' ? 'Customer Profile' :'Restaurant Profile'}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="restaurant-cover-img position-relative">
                  {coverPictureSrc ? (
                    <img
                      src={coverPictureSrc}
                      alt="img"
                      className="img-fluid"
                      style={{ height: 420, width: "100%" }}
                    />
                  ) : vendorData?.length > 0 && vendorData[0]?.cover_picture ? (
                    <img
                      src={`${vendorData[0]?.cover_picture}`}
                      alt="img"
                      className="img-fluid"
                      style={{ height: 420, width: "100%" }}
                    />
                  ) : (
                    <img
                      style={{ height: 420, width: "100%" }}
                      src="/assets/images/resto-cover.png"
                      alt="img"
                      className="img-fluid"
                    />
                  )}

                  <div className="restaurant-cover-btn-div">
                    <input
                      onChange={(e) => {
                        setCoverPicture(e.target.files[0]);
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setCoverPictureSrc(reader.result);
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                      className="d-none"
                      type="file"
                      id="rcb-id"
                    />
                    <label className="btn bg-white" for="rcb-id">
                      Add Cover Image
                    </label>
                  </div>
                </div>

                <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5">
                  <div className="dark-card-header">
                    <h2 className="card-title mb-4">{user?.role_profile_name == 'huserprofile' ? 'Profile information' : 'Restaurant information'}</h2>
                  </div>
                  <form
                    onSubmit={(e) => {
                      createRestaurant(e);
                    }}
                  >
                    <div className="dark-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Restaurant Name *
                            </label>
                            <input
                              onChange={(e) => {
                                setResName(e.target.value);
                              }}
                              value={resname}
                              className="form-control lock-control"
                              type="text"
                              placeholder="Enter Restaurant Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Your Email *</label>
                            <input
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              value={email}
                              className="form-control lock-control"
                              type="email"
                              placeholder="Enter Email"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Mobile Number *
                            </label>
                            <input
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              value={phone}
                              className="form-control lock-control"
                              type="text"
                              placeholder="Enter Mobile Number"
                              required
                              maxLength={8}
                              minLength={8}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Address *</label>
                            <input
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                              value={address}
                              className="form-control"
                              type="text"
                              placeholder="Enter Address"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Restaurant Description *
                            </label>
                            <textarea
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              value={description}
                              className="form-control"
                              rows="4"
                              placeholder="Enter Restaurant Description"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-9 order-2 order-sm-2 order-md-1 mt-3 mt-sm-3 mt-md-0">
                          <p className="m-0 text-center text-sm-center text-md-start">
                            To get started, please add your restaurant packages
                            to our platform.{" "}
                            <a href="#" className="text-primary">
                              <u>Add Packages</u>
                            </a>
                          </p>
                        </div>
                        <div className="col-md-3 order-1 order-sm-1 order-md-2">
                          <div className="form-group text-center text-sm-center text-md-end">
                            <button
                              type="submit"
                              className="btn btn-primary py-3 px-5"
                            >
                              {vendorData.length > 0 ? "Update" : "Create"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorRestaurantProfileDetail;
