import React from 'react'
import CartItem from "../../components/CartItem";
import {Link} from "react-router-dom";

function MyOrder() {
  return (


      <div className='py-5'>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-primary">Order Detail</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-md-12'>
              <div className='dark-card-header'>
                <h2 className='card-title mb-4'>Order Detail</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className='account-right'>

                <div className='res-dark-card mb-5'>
                  <div className='res-header'>
                    <div className='res-img-div'>
                      <img src='/assets/images/item-img2.png' alt='img' className='img-fluid'/>
                    </div>
                    <div className='res-name-div'>
                      <h2>Burger Combo for 2: McChicken Burger</h2>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                  </div>
                  <div className='res-dark-body'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Order ID</label>
                          <h6>#1234165</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Order Date</label>
                          <h6>24-06-2024</h6>
                        </div>
                      </div>

                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Units</label>
                          <h6>25</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Address</label>
                          <h6>Husseiniya Sayyid
                            al-Shuhada</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>package</label>
                          <h6>Large package</h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Phone Number</label>
                          <h6>123456789 </h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        <div className='res-card-group'>
                          <label>Payment Method</label>
                          <h6>Online</h6>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className='res-dark-footer text-end'>
                    <button className='btn btn-primary'>Download Invoice</button>
                  </div>
                </div>


              </div>
            </div>
            <div className="col-lg-4">
              <div className="checkout-right">
              <div className="checkout-details">
                  <h4 className="text-uppercase">Order summary</h4>
                  <div className="checkout-value">
                    <div className="row">
                      <div className="col-6">
                        <p>Subtotal</p>
                      </div>
                      <div className="col-6">
                        <p className="text-end">
                           30 KD
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>Delivery Fee</p>
                      </div>
                      <div className="col-6">
                        <p className="text-end">KD 10</p>
                      </div>
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col-6">
                        <h5>Total Amount</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="text-end">
                           40 KD
                        </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


  )
}

export default MyOrder