import './App.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './screens/Home';
import RestaurantDetail from './screens/RestaurantDetail';
import RestaurantList from './screens/RestaurantList';
import ZakiriDetail from './screens/ZakiriDetail';
import ZakiriList from './screens/ZakiriList';
import OccasionsZakereenList from './screens/OccasionsZakereenList';
import EventDetail from './screens/EventDetail';
import OccasionsList from './screens/OccasionsList';
import ConfirmReservations from './screens/ConfirmReservations';
import CheckOut from './screens/CheckOut';
import Address from './screens/Address';
import TermsandCondition from './screens/TermsandCondition';
import CookiePolicy from './screens/CookiePolicy';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactUs from './screens/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';
import VendorProfile from './screens/vendors/VendorProfile';
import MyReservations from './screens/vendors/MyReservations';
import { userContext, UserContext } from './context/UserContext';
import VendorZakiriProfileDetail from './screens/vendors/VendorZakiriProfileDetail';
import VendorZakiriPackages from './screens/vendors/VendorZakiriPackages';
import VendorZakiriAddPackages from './screens/vendors/VendorZakiriAddPackages';
import VendorRestaurantProfileDetail from './screens/vendors/VendorRestaurantProfileDetail';
import VendorRestaurantPackages from './screens/vendors/VendorRestaurantPackages';
import VendorRestaurantAddPackages from './screens/vendors/VendorRestaurantAddPackages';
import MyOrder from './screens/vendors/MyOrder';
import OrderDetail from './screens/vendors/OrderDetail';
import Loader from './components/Loader';

// vendor-restaurant-profile-details
// vendor-restaurant-packages
// vendor-restaurant-add-package

function App() {
  const {load} = useContext(userContext)
  return (
    <>
      {load && <Loader />}
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route index element={<>
            <Header />
            <Home />
            <Footer />
          </>} />
          <Route exact path="RestaurantDetail" element={<>
            <Header />
            <RestaurantDetail />
            <Footer />
          </>} />
          <Route exact path="RestaurantList" element={<>
            <Header />
            <RestaurantList />
            <Footer />
          </>} />
          <Route exact path="/ZakiriDetail" element={<>
            <Header />
            <ZakiriDetail />
            <Footer />
          </>} />
          <Route exact path="/ZakiriList" element={<>
            <Header />
            <ZakiriList />
            <Footer />
          </>} />
          <Route exact path="/OccasionsZakereenList" element={<>
            <Header />
            <OccasionsZakereenList />
            <Footer />
          </>} />
          <Route exact path="/EventDetail" element={<>
            <Header />
            <EventDetail />
            <Footer />
          </>} />
          <Route exact path="/OccasionsList" element={<>
            <Header />
            <OccasionsList />
            <Footer />
          </>} />
          <Route exact path="CheckOut" element={<>
            <Header />
            <CheckOut />
            <Footer />
          </>} />
          <Route exact path="Address" element={<>
            <Header />
            <Address />
            <Footer />
          </>} />
          <Route exact path="TermsandCondition" element={<>
            <Header />
            <TermsandCondition />
            <Footer />
          </>} />
          <Route exact path="CookiePolicy" element={<>
            <Header />
            <CookiePolicy />
            <Footer />
          </>} />
          <Route exact path="PrivacyPolicy" element={<>
            <Header />
            <PrivacyPolicy />
            <Footer />
          </>} />
          <Route exact path="ContactUs" element={<>
            <Header />
            <ContactUs />
            <Footer />
          </>} />
          <Route exact path="/vendor-profile" element={<>
            <Header />
            <VendorProfile />
            <Footer />
          </>} />
          <Route exact path="/MyReservations" element={<>
            <Header />
            <MyReservations />
            <Footer />
          </>} />
          <Route exact path="/ConfirmReservations" element={<>
            <Header />
            <ConfirmReservations />
            <Footer />
          </>} />
          <Route exact path="/vendor-restaurant-profile-details" element={<>
            <Header />
            <VendorRestaurantProfileDetail />
            <Footer />
          </>} />
          <Route exact path="/vendor-restaurant-packages" element={<>
            <Header />
            <VendorRestaurantPackages />
            <Footer />
          </>} />
          <Route exact path="/orders" element={<>
            <Header />
            <MyOrder />
            <Footer />
          </>} />
          <Route exact path="/OrderDetail" element={<>
            <Header />
            <OrderDetail />
            <Footer />
          </>} />
          <Route exact path="/vendor-zakiri-packages" element={<>
            <Header />
            <VendorZakiriPackages />
            <Footer />
          </>} />
          <Route exact path="/vendor-zakiri-add-package" element={<>
            <Header />
            <VendorZakiriAddPackages />
            <Footer />
          </>} />

          <Route exact path="/vendor-restaurant-add-package" element={<>
            <Header />
            <VendorRestaurantAddPackages />
            <Footer />
          </>} />
          <Route exact path="/Vendor-zakiri-profile-detail" element={<>
            <Header />
            <VendorZakiriProfileDetail />
            <Footer />
          </>} />


        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App