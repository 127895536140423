import React, { useContext, useEffect, useState } from "react";
import { node_image_url, url } from "../../helpers";
import { toast } from "react-toastify";
import Sidebar from "../../components/Sidebar";
import { userContext } from "../../context/UserContext";
import { Link, useLocation } from "react-router-dom";

function VendorRestaurantAddPackages() {
  const { user, vendorData, setLoad } = useContext(userContext);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [minimum_unit, setMinimumUnit] = useState("");
  const [pacakge_image, setPacakgeImage] = useState("");
  const [packages, setPackages] = useState([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const package_index = useQuery().get("index");

  useEffect(() => {
    async function fetchPackages() {
      const response = await fetch(
        url + `resource/Restaurant/${vendorData[0]?.name}`,
        {
          headers: {
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
        }
      );

      if (response.ok == true) {
        const data = await response.json();
        if (data?.data?.restaurant_package?.length > 0) {
          setPackages(
            data?.data?.restaurant_package?.map((item) => {
              return {
                package_name: item?.package_name,
                package_unit_price: item?.package_unit_price,
                package_description: item?.package_description,
                package_minimum_unit: item?.package_minimum_unit,
                pacakge_image: item?.pacakge_image,
                doctype: "Restaurant Package",
              };
            })
          );
        }
      }
    }

    if (vendorData.length > 0) {
      fetchPackages();
    }
  }, [vendorData]);

  useEffect(() => {
    if (packages?.length > 0 && package_index > -1) {
      const item = packages[package_index]
      setName(item?.package_name)
      setPrice(item?.package_unit_price)
      setDescription(item?.package_description)
      setMinimumUnit(item?.package_minimum_unit)
    }
  }, [packages])

  async function AddPackages(e) {
    e.preventDefault();
    setLoad(true);
    let all_packages = packages;

    let package_data = {
      package_name: name,
      package_unit_price: price,
      package_description: description,
      package_minimum_unit: minimum_unit,
      pacakge_image: "",
      doctype: "Restaurant Package",
    };


    if (pacakge_image) {
      const formData = new FormData();
      formData.append("image", pacakge_image, pacakge_image.name);

      const pacakge_image_response = await fetch(
        node_image_url + "/upload-files-bg",
        {
          method: "POST",
          body: formData,
        }
      );

      if (pacakge_image_response.ok == true) {
        setLoad(false);
        const pacakge_image_data = await pacakge_image_response.json();

        if (pacakge_image_data?.status == 200) {
          package_data.pacakge_image =
            node_image_url + pacakge_image_data?.file_url;
        }
      }
    }

    let ExsistPackage = []

    if (package_index) {
      ExsistPackage = packages.filter((item, index) => item?.package_name?.toLowerCase() == package_data?.package_name?.toLowerCase() && index != package_index)

      if (ExsistPackage.length > 0) {
        toast.error("This Package Already Exist!");
        return
      } else {
        all_packages = all_packages.map((item, index) => {
          if (index == package_index) {
            return package_data
          } else {
            return item
          }
        })
      }
    } else {
      console.log(packages[0])
      ExsistPackage = packages.filter((item) => item?.package_name?.toLowerCase() == name?.toLowerCase())
      if (ExsistPackage.length > 0) {
        toast.error("This Package Already Exist!");
        return
      } else {
        all_packages.push(package_data);
      }
    }

    console.log(ExsistPackage)

    if (ExsistPackage.length == 0) {
      const send_data = {
        restaurant_package: all_packages,
      };



      const response = await fetch(
        url + `resource/Restaurant/${vendorData[0]?.name}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          body: JSON.stringify(send_data),
        }
      );

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        if (data?.data?.name) {
          if (package_index) {
            toast.success("Packages Updated successfully!");
          } else {
            toast.success("Packages created successfully!");
          }
          setTimeout(() => {
            window.location.reload();
          }, 700);
        }
      }
    }else{
      setLoad(false)
    }
  }

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Restaurant Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="dark-card-header">
                  <h2 className="card-title m-4">
                    {package_index ? 'Update Package' : "Add Packages"}
                  </h2>
                </div>

                <form
                  onSubmit={(e) => {
                    AddPackages(e);
                  }}
                >
                  <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5">
                    <div className="dark-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Package Name *</label>
                            <input
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              className="form-control"
                              type="text"
                              placeholder="Enter Package Name"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Package Price *
                            </label>
                            <input
                              onChange={(e) => {
                                setPrice(e.target.value);
                              }}
                              value={price}
                              className="form-control"
                              type="number"
                              placeholder="Enter Package Price"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Package Minimum Unit *
                            </label>
                            <input
                              onChange={(e) => {
                                setMinimumUnit(e.target.value);
                              }}
                              value={minimum_unit}
                              className="form-control"
                              type="number"
                              placeholder="Enter Minimum Unit"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3 custom-file-box">
                            <label className="form-label">
                              Package Image *
                            </label>
                            <input
                              onChange={(e) => {
                                setPacakgeImage(e.target.files[0]);
                              }}
                              className="form-control"
                              type="file"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Package Description *
                            </label>
                            <textarea
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              value={description}
                              className="form-control"
                              rows="4"
                              placeholder="Enter Description"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-center text-sm-center text-md-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary py-3 px-5"
                            >
                              {package_index ? 'Update Package' : 'Add Package'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorRestaurantAddPackages;
