import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { url } from "../helpers";
import { userContext } from "../context/UserContext";
import { toast } from "react-toastify";
import RestaurantPackageDetail from "../components/RestaurantPackageDetail";
import UserLoggedInWarning from "../components/UserLoggedInWarning";

function RestaurantDetail() {
  const { setLoad, AddToCart, user } = useContext(userContext);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const res_name = useQuery().get("name");
  const [RestaurantDetail, setRestaurantDetail] = useState([]);
  const [RestaurantPackage, setRestaurantPackage] = useState([]);

  useEffect(() => {
    if (res_name) {
      async function fetchResturentDetails() {
        setLoad(true);
        const response = await fetch(url + `resource/Restaurant/${res_name}`, {
          headers: {
            Authorization: "token 780df365e7e49ec:d6e94a18f55d1ce",
          },
        });

        if (response.ok == true) {
          const data = await response.json();
          setLoad(false);
          console.log(data?.data)
          setRestaurantDetail(data?.data);
          setRestaurantPackage(data?.data?.restaurant_package);
        } else {
          setLoad(false);
          toast.error("Internal Server Error");
        }
      }

      fetchResturentDetails();
    }
  }, [res_name]);

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Restaurant Detail
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="resto-profile-div">
          <div className="resto-cover-img">
            {RestaurantDetail?.cover_picture ? (
              <img
                src={`${RestaurantDetail?.cover_picture}`}
                alt="img"
                className="img-fluid"
              />
            ) : (
              <img
                src="/assets/images/resto-cover.png"
                alt="img"
                className="img-fluid"
              />
            )}
          </div>
          <div className="resto-cover-bottom">
            <div className="resto-profile">
              {RestaurantDetail?.profile_picture ? (
                <img
                  src={`${RestaurantDetail?.profile_picture}`}
                  alt="img"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="/assets/images/resto-profile.png"
                  alt="img"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="resto-name-detail">
              <h4>{RestaurantDetail?.res_name}</h4>
              <p>Burger, F`ast Food, Beverages</p>
            </div>
          </div>
        </div>
        <div className="resto-description mt-4">
          <h5 className="font-regular">
            <u>Description</u>
          </h5>
          <p className="text-light">
            {RestaurantDetail?.restaurant_description}
          </p>
        </div>
        <hr />
        {user?.type != 'guest' ? <div className="resto-item-list-div">
          <div className="row align-items-center mb-5">
            <div className="col-6">
              <h2 className="resto-item-title">Food Menu</h2>
            </div>
            <div className="col-6">
              <div className="item-search-box position-relative">
                <input className="form-control" placeholder="Search.." />
                <button className="btn">
                  <i className="fas fa-search text-white"></i>
                </button>
              </div>
            </div>
          </div>
          {RestaurantPackage.map((resp, index) => (
            <RestaurantPackageDetail resp={resp} AddToCart={AddToCart} key={index} />
          ))}
        </div> : <UserLoggedInWarning />}

      </div>
    </div>
  );
}

export default RestaurantDetail;
