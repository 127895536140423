import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { userContext } from "../context/UserContext";
import { url } from "../helpers";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function RestaurantList() {

  const { user, setLoad } = useContext(userContext); 
  const [page,setPage] = useState(0); 
  const[resturent,setResturent] = useState([]);

  async function fetchResturent() { 
    setLoad(true)
    const response = await fetch(
      url +
        `resource/Restaurant?fields=[%22*%22]&limit_page_length=20&limit_start=${page * 20}&filters=[[%22verification_status%22%2C%20%22%3D%22%2C%20%22Verified%22]]`,{ 
        headers:{
          Authorization: 'token 780df365e7e49ec:d6e94a18f55d1ce'
        } 
      }
    ); 

    if(response.ok == true){ 
      setLoad(false)
      const data = await response.json(); 
      setResturent(data?.data)
    }else{ 
      setLoad(false) 
      toast.error("Internal Server Error!")
    }
  } 

  useEffect(()=>{
    fetchResturent()
  },[page])
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">Zakiri List</li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="restaurant-list py-5">
            <div className="row align-items-center mb-5">
              <div className="col-6">
                <h2 className="resto-item-title">Restaurants</h2>
              </div>
              <div className="col-6">
                <div className="item-search-box position-relative">
                  <input className="form-control" placeholder="Search.." />
                  <button className="btn">
                    <i className="fas fa-search text-white"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="row"> 
              {resturent?.length > 0 && resturent?.map((item,i)=>(
                <div className="col-6 col-sm-4 col-md-3 col-lg-3">
                  <div className="restaurant-card position-relative mb-4">
                    <a href="#">
                      <div className="restaurant-img"> 
                        {item?.profile_picture ? (
                          <img
                            src={`${item?.profile_picture}`}
                            alt="img"
                            className="img-fluid"
                            width="100%"
                          />    
                        ) : (
                          <img
                            src="/assets/images/restaurant-img.png"
                            alt="img"
                            className="img-fluid"
                            width="100%"
                          />    
                        )
                        
                      }
                      </div>
                      <div className="restaurant-dtl">
                        <h5 className="text-white mb-1">{item?.res_name}</h5>
                        <p className="text-light mb-0">
                          Pizza, Pasta, Italian, Fast Food
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div className="pagination-div text-center mt-4">
              <nav aria-label="...">
                <ul className="pagination justify-content-center">
                  <li style={{cursor: 'pointer'}} className={page == 0 ? 'page-item disabled': 'page-item'} onClick={()=>{
                    if(page >= 1){ 
                      setPage(page - 1)
                    }
                  }}>
                    <span className="page-link">Previous</span>
                  </li>
                  
                  <li className="page-item active">
                    <span className="page-link">{page + 1}</span>
                  </li>
                  
                  <li className="page-item" onClick={()=>{ setPage(page + 1)}}>
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantList;
