import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../context/UserContext';

function RestaurantPackageDetail(props) {
    const { cart } = useContext(userContext)
    const { resp, AddToCart } = props;
    const [qty, setQty] = useState(0)
    useEffect(() => {
        if (cart?.restaurant_package) {
            
            let result = cart?.restaurant_package?.filter(item => {
                if ((item?.restaurant_owner == resp?.parent) && (item?.package_name == resp?.package_name)) {
                    return item
                }
            })
            
            if (result?.length > 0) {
                setQty(Number(result[0]?.order_quantity))
            }
        }
    }, [cart]) 
    return (
        <div className="resto-menu">
            <div className="resto-menu-card">
                <div className="resto-img-div">
                    {resp?.pacakge_image ? (
                        <img
                            src={`${resp?.pacakge_image}`}
                            alt="img"
                            className="img-fluid"
                        />
                    ) : (
                        <img
                            src="/assets/images/item-img2.png"
                            alt="img"
                            className="img-fluid"
                        />
                    )}
                </div>
                <div className="resto-item-detail">
                    <h4>{resp?.package_name}</h4>
                    <h5>{resp?.package_unit_price} KD</h5>
                    <p className="text-light">{resp?.package_description}</p>
                    {qty > 0 ? <div className="qty-group-box">
                        <button onClick={() => {
                            if (qty > 0) {
                                setQty((qty - 1) < resp?.package_minimum_unit ? 0 : qty - 1)
                                
                                AddToCart('restaurant', {
                                    "package_id": resp?.package_id,
                                    "package_name": resp?.package_name,
                                    "package_unit_price": resp?.package_unit_price,
                                    "package_description": resp?.package_description,
                                    "package_minimum_unit": resp?.package_minimum_unit,
                                    "order_quantity": (qty - 1) < resp?.package_minimum_unit ? 0 : (qty - 1),
                                    "pacakge_image": resp?.pacakge_image, 
                                    "restaurant_owner": resp?.parent
                                })
                            }
                        }} className="btn btn-minus">
                            <i className="fas fa-minus"></i>
                        </button>
                        <input className="form-control" type="number" min={1} value={qty} onChange={e => setQty(e.target.value)} />
                        <button onClick={() => {
                            setQty(qty + 1)

                            AddToCart('restaurant', {
                                "package_id": resp?.package_id,
                                "package_name": resp?.package_name,
                                "package_unit_price": resp?.package_unit_price,
                                "package_description": resp?.package_description, 
                                "package_minimum_unit": resp?.package_minimum_unit, 
                                "pacakge_image": resp?.pacakge_image, 
                                "order_quantity": qty + 1,
                                "restaurant_owner": resp?.parent
                            })

                        }} className="btn btn-plus">
                            <i className="fas fa-plus"></i>
                        </button>
                    </div> : <button onClick={() => {
                        setQty(resp?.package_minimum_unit)
                        AddToCart('restaurant', {
                            "package_id": resp?.package_id,
                            "package_name": resp?.package_name,
                            "package_unit_price": resp?.package_unit_price,
                            "package_description": resp?.package_description,
                            "package_minimum_unit": resp?.package_minimum_unit, 
                            "pacakge_image": resp?.pacakge_image,                            
                             "order_quantity" : resp?.package_minimum_unit,
                            "restaurant_owner": resp?.parent
                        })
                    }} className="btn btn-outline-light add-to-cart-btn text-uppercase text-center">
                        Add to Cart
                    </button>}

                </div>
            </div>
        </div>
    )
}

export default RestaurantPackageDetail