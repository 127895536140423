import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { url } from "../../helpers";
import { userContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";

function VendorZakiriAddPackages() {
  const { user, vendorData, setLoad } = useContext(userContext);

  const [PackageName, setPackageName] = useState("");
  const [PackageUnitPrice, setPackageUnitPrice] = useState("");
  const [PackageDescription, setPackageDescription] = useState("");
  const [PackageMinimumUnit, setPackageMinimumUnit] = useState("1");
  const [Addpackages, setAddPackages] = useState([]); // The state to hold packages

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const qaari_package_index = useQuery().get("index");

  useEffect(() => {
    if (Addpackages.length > 0 && qaari_package_index > -1) {
      const item = Addpackages[qaari_package_index];
      setPackageName(item?.package_name);
      setPackageUnitPrice(item?.package_unit_price);
      setPackageMinimumUnit(item?.package_minimum_unit);
      setPackageDescription(item?.package_description);
    }
  }, [Addpackages]);


  useEffect(() => {
    async function fetchPackages() {
      const response = await fetch(
        url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
        {
          headers: {
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.data?.packages?.length > 0) {
          setAddPackages(
            data?.data?.packages.map((item) => {
              return {
                package_name: item?.package_name,
                package_unit_price: item?.package_unit_price,
                package_description: item?.package_description,
                package_minimum_unit: item?.package_minimum_unit,
              };
            })
          );
        }
      }
    }

    if (vendorData.length > 0) {
      fetchPackages();
    }
  }, [vendorData]);

  async function ZakiriAddPackages(e) {
    e.preventDefault();
    setLoad(true);
    let all_packages = Addpackages;

    const newPackage = {
      package_name: PackageName,
      package_unit_price: PackageUnitPrice,
      package_description: PackageDescription,
      package_minimum_unit: PackageMinimumUnit,
    };

    let Exsisting_pacakage_name = []
    if (qaari_package_index) {
      Exsisting_pacakage_name = all_packages.filter((item, index) => item?.package_name?.toLowerCase() == PackageName?.toLowerCase() && index != qaari_package_index)
      if (Exsisting_pacakage_name.length > 0) {
        setLoad(false)
        toast.error("This Package Name Already Exist!")
        return
      } else {
        all_packages = all_packages.map((item, index) => {
          if (index == qaari_package_index) {
            return newPackage
          } else {
            return item
          }
        })
      }
    } else {
      Exsisting_pacakage_name = all_packages.filter((item) => item?.package_name?.toLowerCase() == PackageName?.toLowerCase())

      if (Exsisting_pacakage_name.length > 0) {
        setLoad(false)
        toast.error("This Package Already Exist!") 
        return
      } else {
        all_packages.push(newPackage);
      }
    }

    const send_data = {
      packages: all_packages,
    };

    if (Exsisting_pacakage_name.length == 0) {
      const response = await fetch(
        url + `resource/Qaari Zakreen/${vendorData[0]?.name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          method: "PUT",
          body: JSON.stringify(send_data),
        }
      );

      if (response.ok == true) {
        setLoad(false);
        const data = await response.json();
        if (qaari_package_index) {
          toast.success("Package Updated Successfully!");
        } else {
          toast.success("Packages Created Successfully!");
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setLoad(false);
        toast.error("Internal Server Error");
      }
    }
  }

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">
                    Zakiri Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="dark-card-header">
                  <h2 className="card-title m-4">{qaari_package_index ? 'Update Packages' : 'Add Packages'}</h2>
                </div>

                <div className="dark-card p-3 p-sm-3 p-md-3 p-lg-5">
                  <form
                    onSubmit={(e) => {
                      ZakiriAddPackages(e);
                    }}
                  >
                    <div className="dark-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Package Name *</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Package Name"
                              onChange={(e) => {
                                setPackageName(e.target.value);
                              }}
                              value={PackageName} 
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">Price *</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Enter Price"
                              onChange={(e) => {
                                setPackageUnitPrice(e.target.value);
                              }}
                              value={PackageUnitPrice} 
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label"> Description *</label>
                            <textarea
                              className="form-control"
                              rows="4"
                              placeholder="Enter Description"
                              onChange={(e) => {
                                setPackageDescription(e.target.value);
                              }}
                              value={PackageDescription} 
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-center text-sm-center text-md-end mt-4">
                            <button
                              className="btn btn-primary py-3 px-5"
                              type="submit"
                            >
                              {qaari_package_index ? 'Update Packages' : 'Add Packages'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorZakiriAddPackages;
