import React, { useContext, useState } from 'react'
import { url } from '../helpers'
import { userContext } from '../context/UserContext'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

function Header() {
    const { setUser, user, setLoad,cartCount } = useContext(userContext)
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        setLoad(true)

        const send_data = {
            "data": {
                "name": email,
                "email": email,
                "mobile_no": mobile,
                "first_name": name,
                "full_name": name,
                "username": name,
                "role_profile_name": type,
                "new_password": password
            }
        }

        const response = await fetch(url + 'resource/User', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `token 002494517b6b9d3:492453d158eac82`
            },
            body: JSON.stringify(send_data)
        })

        if (response.ok == true) {
            const data = await response.json()
            setLoad(false)
            if (data?.data?.name) {
                document.getElementById('register-modal-close-btn').click()
                toast.success('User created successfully!')
            }

        } else {
            setLoad(false)
            toast.error('Internal server error')
        }

    }

    async function loginSubmit(e) {
        e.preventDefault()
        setLoad(true)

        const response = await fetch(url + `method/husainia.api.login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `token 002494517b6b9d3:492453d158eac82`
            },
            body: JSON.stringify({
                usr: email,
                pwd: password
            })
        })

        if (response.ok == true) {
            const data = await response.json()
            setLoad(false)

            if (data?.full_name) {
                setUser({
                    role_profile_name: data?.message?.role_profile_name,
                    full_name: data?.full_name,
                    message: {
                        api_key: data?.message?.api_key,
                        api_secret: data?.message?.api_secret,
                        email: data?.message?.email,
                        username: data?.message?.username
                    }
                })
                toast.success('User logged in successfully!')
                document.getElementById('login-modal-close-btn').click()
            } else {
                setLoad(false)
                toast.error('Incorrect Credentials')
            }
        }
    }

    function logout() {
        setUser({
            type: 'guest',
            full_name: 'guest',
            message: {
                api_key: '780df365e7e49ec',
                api_secret: 'd6e94a18f55d1ce'
            }

        })

        window.location = window.location.origin + '/'
    }

    return (
        <header className='front-header bg-dark'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="/">
                                    <img src='/assets/images/logo.png' alt='img' className='img-fluid' />
                                </Link>

                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarText"
                                    aria-controls="navbarText" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse justify-content-between navbar-header-menu"
                                    id="navbarText">
                                    <ul className="navbar-nav m-auto mt-0 mb-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Offer</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`/ContactUs`}>
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className='header-right-div'>
                                        <div className='d-flex hrd-main gap-3'>
                                            <div className="nav-item language-dropdown dropdown">
                                                <a className="nav-link dropdown-toggle text-white" href="#"
                                                    id="navbarDarkDropdownMenuLink" role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <img src='/assets/images/eng-flag.png' alt='img'
                                                        className='img-fluid' width='37' />
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-dark"
                                                    aria-labelledby="navbarDarkDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">
                                                        <img src='/assets/images/eng-flag.png' alt='img'
                                                            className='img-fluid' /> English
                                                    </a></li>
                                                    <li><a className="dropdown-item" href="#">
                                                        <img src='/assets/images/kwt-flag.png' alt='img'
                                                            className='img-fluid' /> Arabic
                                                    </a></li>
                                                </ul>
                                            </div>

                                            {user?.role_profile_name == 'huserprofile' &&
                                                <div className='cart-div'>
                                                    <Link to={`/CheckOut`}>
                                                        {cartCount > 0 && <span>
                                                            {cartCount}
                                                        </span>}
                                                        
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17.24 3.58006H16.84L13.46 0.200059C13.19 -0.0699414 12.75 -0.0699414 12.47 0.200059C12.2 0.470059 12.2 0.910059 12.47 1.19006L14.86 3.58006H5.14L7.53 1.19006C7.8 0.920059 7.8 0.480059 7.53 0.200059C7.26 -0.0699414 6.82 -0.0699414 6.54 0.200059L3.17 3.58006H2.77C1.87 3.58006 0 3.58006 0 6.14006C0 7.11006 0.2 7.75006 0.62 8.17006C0.86 8.42006 1.15 8.55006 1.46 8.62006C1.75 8.69006 2.06 8.70006 2.36 8.70006H17.64C17.95 8.70006 18.24 8.68006 18.52 8.62006C19.36 8.42006 20 7.82006 20 6.14006C20 3.58006 18.13 3.58006 17.24 3.58006Z"
                                                                fill="white" />
                                                            <path
                                                                d="M17.0497 10H2.86967C2.24967 10 1.77967 10.55 1.87967 11.16L2.71967 16.3C2.99967 18.02 3.74967 20 7.07967 20H12.6897C16.0597 20 16.6597 18.31 17.0197 16.42L18.0297 11.19C18.1497 10.57 17.6797 10 17.0497 10ZM8.60967 16.45C8.60967 16.84 8.29967 17.15 7.91967 17.15C7.52967 17.15 7.21967 16.84 7.21967 16.45V13.15C7.21967 12.77 7.52967 12.45 7.91967 12.45C8.29967 12.45 8.60967 12.77 8.60967 13.15V16.45ZM12.8897 16.45C12.8897 16.84 12.5797 17.15 12.1897 17.15C11.8097 17.15 11.4897 16.84 11.4897 16.45V13.15C11.4897 12.77 11.8097 12.45 12.1897 12.45C12.5797 12.45 12.8897 12.77 12.8897 13.15V16.45Z"
                                                                fill="white" />
                                                        </svg>
                                                    </Link>

                                                </div>}


                                            {user?.role_profile_name ? <div className='d-flex gap-3'>
                                                <Link to={user?.role_profile_name == 'Qaari' ? "/Vendor-zakiri-profile-detail" : "/vendor-restaurant-profile-details"}
                                                    className='btn btn-outline-light'>
                                                    My Profile
                                                </Link>
                                                <button onClick={() => logout()} type='button'
                                                    className='btn btn-primary'>
                                                    Logout
                                                </button>
                                            </div> : <div className='d-flex gap-3'>
                                                <button type='button' className='btn btn-outline-light'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#Register-Modal'>
                                                    Sign Up
                                                </button>
                                                <button type='button' className='btn btn-primary' data-bs-toggle='modal'
                                                    data-bs-target='#Login-Modal'>
                                                    Login
                                                </button>
                                            </div>}


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {/* Login Modal Start */}
            <div className="modal login-modal modal-light fade" id="Login-Modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-body">
                            <button id='login-modal-close-btn' type="button" className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            <div className='md-body text-center'>
                                <div className="modal-logo-div mb-4">
                                    <img src='/assets/images/logo-2.png' alt='img' className='img-fluid' />
                                    <h4><b>Login</b></h4>
                                </div>
                                <form onSubmit={(e) => loginSubmit(e)}>
                                    <div className='login-form'>
                                        <div className='mb-3'>
                                            <input onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} className='form-control' placeholder='Email' type='email' required />
                                        </div>
                                        <div className='mb-3'>
                                            <input className='form-control' onChange={(e) => {
                                                setPassword(e.target.value)
                                            }} placeholder='Password' type='password' required />
                                        </div>
                                        <div className='mb-3 text-end'>
                                            <a href="#" className='text-light'>Forget Password?</a>
                                        </div>
                                        <div className='btn-div mb-3'>
                                            <button type='submit' className="btn btn-primary text-center w-100 py-3">
                                                Login
                                            </button>
                                        </div>
                                        <div className='mb-3 text-center'>
                                            <p>
                                                New to Husainia? <a href="#" className='text-primary'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#Register-Modal'
                                                    data-bs-dismiss="modal"> Create Account</a>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Login Modal End */}

            {/* Register Modal Start */}
            <div className="modal login-modal modal-light fade" id="Register-Modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-body">
                            <button id='register-modal-close-btn' type="button" className="btn-close"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className='md-body text-center'>
                                <div className="modal-logo-div mb-4">
                                    <img src='/assets/images/logo-2.png' alt='img' className='img-fluid' />
                                    <h4><b>Register</b></h4>
                                </div>

                                <form onSubmit={(e) => {
                                    handleSubmit(e)
                                }}>
                                    <div className='login-form'>
                                        <div className='mb-3'>
                                            <select onChange={(e) => {
                                                setType(e.target.value)
                                            }} className='form-control' required>
                                                <option value=''>Select Type</option>
                                                <option value="huserprofile">User</option>
                                                <option value="Restaurant">Restaurant</option>
                                                <option value="Qaari">Zakereen/Qaari</option>
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }} className='form-control' placeholder='Enter User Name' type='text'
                                                required />
                                        </div>
                                        <div className='mb-3'>
                                            <input onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} className='form-control' placeholder='Enter Email' type='email'
                                                required />
                                        </div>
                                        <div className='mb-3'>
                                            <input onChange={(e) => {
                                                setMobile(e.target.value)
                                            }} className='form-control' placeholder='Phone Number' type='text'
                                                required />
                                        </div>
                                        <div className='mb-3'>
                                            <input onChange={(e) => {
                                                setPassword(e.target.value)
                                            }} className='form-control' placeholder='Password' type='password'
                                                required />
                                        </div>

                                        <div className='btn-div mb-3'>
                                            <button type="submit" className="btn btn-primary text-center w-100 py-3">
                                                Create a New Account
                                            </button>
                                        </div>
                                        <div className='mb-3 text-center'>
                                            <p>
                                                Already have an account? <a href="#" className='text-primary'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#Login-Modal'
                                                    data-bs-dismiss="modal"> Login</a>
                                            </p>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Login Modal End */}

            {/* <div className="preloder">
                <div className="loder">
                    <img src='/assets/images/loader.svg' alt='img' className='img-fluid'/>
                </div>
            </div> */}

        </header>


    )
}

export default Header