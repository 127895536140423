import React from 'react'
import OwlCarousel from "react-owl-carousel";
import {baseUrl} from "../helpers";
import { Link } from 'react-router-dom';

function OccasionsList() {
  return (


      <div className='py-5'>


        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='breadrumb'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item text-primary">Occasions List</li>
                  </ol>
                </nav>
              </div>
            </div>


            <div className='occasions-list py-5'>

              <div className="row align-items-center mb-5">
                <div className="col-6"><h2 className="resto-item-title">Occasions</h2></div>
                <div className="col-6">
                  <div className="item-search-box position-relative"><input className="form-control"
                                                                            placeholder="Search.."/>
                    <button className="btn"><i className="fas fa-search text-white"></i></button>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>

                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>

                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>

                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>
                <div className='col-6 col-sm-4 col-md-4 col-lg-4'>
                  <div className='occasions-card position-relative mb-4'>
                    <a href='#'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                      <h4>Occasions Name</h4>
                    </a>
                  </div>
                </div>

              </div>

              <div className='pagination-div text-center mt-4'>
                <nav aria-label="...">
                  <ul className="pagination justify-content-center">
                    <li className="page-item disabled">
                      <span className="page-link">Previous</span>
                    </li>
                    <li className="page-item active">
                    <span className="page-link">1</span>
                    </li>
                    <li className="page-item">

                      <a className="page-link" href="#">2</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item">
                      <a className="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>

          </div>


        </div>

      </div>
  )
}

export default OccasionsList