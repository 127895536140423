import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useLocation } from "react-router-dom";
import { url } from "../helpers";
import { toast } from "react-toastify";
import { userContext } from "../context/UserContext";

function BirthList() {
  const { setLoad } = useContext(userContext);
  const [occasionQarri, setOccasionQarri] = useState([]); 
  const [page,setPage] = useState(0);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const ocassion_name = useQuery().get("name");

  async function getQaari() {
    setLoad(true);
    const response = await fetch(
      url +
        `resource/Qaari%20Zakreen?fields=[%22*%22]&limit_page_length=20&limit_start=${page * 20}&filters=[[%22Qaari%20Ocassions%22%2C%22ocassions%22%2C%22like%22%2C%22%${ocassion_name}%%22]]`,
      {
        headers: {
          Authorization: "token 780df365e7e49ec:d6e94a18f55d1ce",
        },
      }
    );

    if (response.ok == true) {
      const data = await response.json();
      setLoad(false);
      setOccasionQarri(data?.data);
      console.log(data);
    } else {
      setLoad(false);
      toast.error("Internal Server Error!");
    }
  }

  useEffect(() => {
    getQaari();
  }, [page]);
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary text-capitalize">{ocassion_name}</li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="zakiri-list py-5">
            <div className="row align-items-center mb-5">
              <div className="col-6">
                <h2 className="resto-item-title text-capitalize">{ocassion_name}</h2>
              </div>
              <div className="col-6">
                <div className="item-search-box position-relative">
                  <input className="form-control" placeholder="Search.." />
                  <button className="btn">
                    <i className="fas fa-search text-white"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              {occasionQarri?.length > 0 ? (
                occasionQarri?.map((item, index) => (
                  <div
                    className="col-6 col-sm-4 col-md-3 col-lg-3"
                    index={index}
                  >
                    <div className="zakereen-card position-relative mb-4">
                      <a href="#">
                        <div className="zakereen-img">
                          {item?.profile_picture ? (
                            <img
                              src={`${item?.profile_picture}`}
                              alt="img"
                              className="img-fluid"
                              width="100%"
                            />
                          ) : (
                            <img
                              src="/assets/images/zakereen.png"
                              alt="img"
                              className="img-fluid"
                              width="100%"
                            />
                          )}
                        </div>
                        <div className="zakereen-name">
                          <h4 className="text-white">{item?.qaari_name}</h4>
                        </div>
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="col-md-12 d-flex justify-content-center align-items-center"
                  style={{ height: "200px" }}
                >
                  <h3 className="packeges-text">No Qaari Availabel !</h3>
                </div>
              )}
            </div>
            {occasionQarri?.length > 0 &&
              <div className="pagination-div text-center mt-4">
                <nav aria-label="...">
                  <ul className="pagination justify-content-center">
                    <li style={{cursor: 'pointer'}} className={ page == 0 ? 'page-item disabled' : 'page-item'} onClick={()=>{
                      if(page >= 1){ 
                        setPage(page - 1)
                      }
                    }}>
                      <span className="page-link">Previous</span>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">{page + 1}</span>
                    </li>
                    <li className="page-item" onClick={()=>{setPage(page + 1)}}>
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthList;
