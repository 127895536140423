import React from 'react'
import { Link } from 'react-router-dom'

function VendorProfile() {
  return (
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='breadrumb'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item text-primary">Profile</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className=''>
            <div className='row'>
              <div className='col-md-4 col-lg-4 col-xl-3'>
                <div className='account-left mb-4'>
                  <div className='account-profile mb-4'>
                    <div className='profile-img'>
                      <img src='/assets/images/occasins-img1.png' alt='img' className='img-fluid' width='100%'/>
                    </div>
                    <div className='profile-detail'>
                      <h4>John Doe</h4>
                      <p>johndoe@mail.com</p>
                    </div>
                  </div>
                  <div className='account-menu'>
                    <div className='account-menu-link'>
                      <a className='active' href='#'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                              fill="white"/>
                          <path
                              d="M17.08 14.1499C14.29 12.2899 9.73996 12.2899 6.92996 14.1499C5.65996 14.9999 4.95996 16.1499 4.95996 17.3799C4.95996 18.6099 5.65996 19.7499 6.91996 20.5899C8.31996 21.5299 10.16 21.9999 12 21.9999C13.84 21.9999 15.68 21.5299 17.08 20.5899C18.34 19.7399 19.04 18.5999 19.04 17.3599C19.03 16.1299 18.34 14.9899 17.08 14.1499Z"
                              fill="white"/>
                        </svg>

                        Edit My Profile
                      </a>
                    </div>
                    <div className='account-menu-link'>
                      <a className='active' href='#'>
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_173_2343)">
                            <path
                                d="M8.84637 0C8.14737 0 7.58264 0.558594 7.58264 1.25V2C4.69974 2.57812 2.52769 5.10156 2.52769 8.125V8.85938C2.52769 10.6953 1.84449 12.4688 0.612343 13.8438L0.320104 14.168C-0.0116269 14.5352 -0.0906104 15.0625 0.110798 15.5117C0.312205 15.9609 0.766361 16.25 1.26396 16.25H16.4288C16.9264 16.25 17.3766 15.9609 17.582 15.5117C17.7873 15.0625 17.7044 14.5352 17.3726 14.168L17.0804 13.8438C15.8483 12.4688 15.1651 10.6992 15.1651 8.85938V8.125C15.1651 5.10156 12.993 2.57812 10.1101 2V1.25C10.1101 0.558594 9.54538 0 8.84637 0ZM10.6354 19.2695C11.1093 18.8008 11.3738 18.1641 11.3738 17.5H8.84637H6.3189C6.3189 18.1641 6.5835 18.8008 7.0574 19.2695C7.5313 19.7383 8.17501 20 8.84637 20C9.51773 20 10.1614 19.7383 10.6354 19.2695Z"
                                fill="white"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_173_2343">
                              <rect width="17.6923" height="20" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>


                        My Reservations
                      </a>
                    </div>
                    <div className='account-menu-link'>
                      <a href='#'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM11.25 13.75H6.75C6.34 13.75 6 13.41 6 13C6 12.59 6.34 12.25 6.75 12.25H11.25C11.66 12.25 12 12.59 12 13C12 13.41 11.66 13.75 11.25 13.75ZM12 9.75H6C5.59 9.75 5.25 9.41 5.25 9C5.25 8.59 5.59 8.25 6 8.25H12C12.41 8.25 12.75 8.59 12.75 9C12.75 9.41 12.41 9.75 12 9.75Z"
                              fill="#CDCDCD"/>
                          <path
                              d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z"
                              fill="#CDCDCD"/>
                        </svg>

                        order history
                      </a>
                    </div>
                    <div className='account-menu-link'>
                      <a href='#'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M13.25 12C13.9404 12 14.5 11.4404 14.5 10.75C14.5 10.0596 13.9404 9.5 13.25 9.5C12.5596 9.5 12 10.0596 12 10.75C12 11.4404 12.5596 12 13.25 12Z"
                              fill="#CDCDCD"/>
                          <path
                              d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.89 13.47C14.86 14.49 13.39 14.81 12.09 14.4L11.03 15.45C10.94 15.54 10.78 15.54 10.68 15.45L9.71 14.48C9.57 14.34 9.33 14.34 9.18 14.48C9.03 14.62 9.04 14.86 9.18 15.01L10.15 15.98C10.25 16.08 10.25 16.24 10.15 16.33L9.74 16.74C9.57 16.92 9.24 17.03 9 17L7.91 16.85C7.55 16.8 7.22 16.46 7.16 16.1L7.01 15.01C6.97 14.77 7.09 14.44 7.25 14.27L9.6 11.92C9.2 10.62 9.51 9.15 10.54 8.12C12.01 6.65 14.41 6.65 15.89 8.12C17.37 9.59 17.37 11.99 15.89 13.47Z"
                              fill="#CDCDCD"/>
                        </svg>

                        Change Password
                      </a>
                    </div>
                    <div className='account-menu-link'>
                      <a href='#'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12.678 19.957C12.9528 20.0209 12.9779 20.3807 12.7103 20.4699L11.1303 20.9899C7.16034 22.2699 5.07034 21.1999 3.78034 17.2299L2.50034 13.2799C1.22034 9.30992 2.28034 7.20992 6.25034 5.92992L6.77434 5.75639C7.1772 5.62297 7.56927 6.02703 7.45487 6.43571C7.39817 6.63828 7.34362 6.84968 7.29034 7.06992L6.31034 11.2599C5.21034 15.9699 6.82034 18.5699 11.5303 19.6899L12.678 19.957Z"
                              fill="#CDCDCD"/>
                          <path
                              d="M17.1699 3.21001L15.4999 2.82001C12.1599 2.03001 10.1699 2.68001 8.99994 5.10001C8.69994 5.71001 8.45994 6.45001 8.25994 7.30001L7.27994 11.49C6.29994 15.67 7.58994 17.73 11.7599 18.72L13.4399 19.12C14.0199 19.26 14.5599 19.35 15.0599 19.39C18.1799 19.69 19.8399 18.23 20.6799 14.62L21.6599 10.44C22.6399 6.26001 21.3599 4.19001 17.1699 3.21001ZM15.2899 13.33C15.1999 13.67 14.8999 13.89 14.5599 13.89C14.4999 13.89 14.4399 13.88 14.3699 13.87L11.4599 13.13C11.0599 13.03 10.8199 12.62 10.9199 12.22C11.0199 11.82 11.4299 11.58 11.8299 11.68L14.7399 12.42C15.1499 12.52 15.3899 12.93 15.2899 13.33ZM18.2199 9.95001C18.1299 10.29 17.8299 10.51 17.4899 10.51C17.4299 10.51 17.3699 10.5 17.2999 10.49L12.4499 9.26001C12.0499 9.16001 11.8099 8.75001 11.9099 8.35001C12.0099 7.95001 12.4199 7.71001 12.8199 7.81001L17.6699 9.04001C18.0799 9.13001 18.3199 9.54001 18.2199 9.95001Z"
                              fill="#CDCDCD"/>
                        </svg>

                        Terms & Conditions
                      </a>
                    </div>
                    <div className='account-menu-link'>
                      <a href='#'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M20.5098 10.6199V9.91196C20.5098 5.00428 16.5055 1 11.5978 1C6.69016 1 2.68588 5.00428 2.68588 9.91196V10.6199C1.12324 11.206 0 12.7197 0 14.4777C0 16.7484 1.8556 18.6041 4.1264 18.6041H5.6158C6.0065 18.6041 6.32378 18.2867 6.32378 17.8961V11.0596C6.32378 10.6689 6.00632 10.3516 5.6158 10.3516H4.93214V9.91218C4.93214 6.22535 7.91093 3.24652 11.5978 3.24652C15.2847 3.24652 18.2635 6.22531 18.2635 9.91218V10.3516H17.5798C17.1891 10.3516 16.8718 10.669 16.8718 11.0596V17.8961C16.8718 18.2868 17.1893 18.6041 17.5798 18.6041H17.8973C17.1893 20.24 15.6266 21.3876 13.7709 21.534C13.6733 21.0701 13.258 20.7039 12.7454 20.7039H10.4259C9.83983 20.7039 9.37605 21.1679 9.37605 21.7537V22.9502C9.37605 23.5362 9.84 24 10.4259 24H12.7454C13.2582 24 13.6976 23.6338 13.7709 23.121C16.5299 22.9745 18.825 21.0945 19.6064 18.5552C21.6086 18.2866 23.1712 16.5529 23.1712 14.4776C23.1956 12.6955 22.0724 11.2061 20.5097 10.62L20.5098 10.6199Z"
                              fill="#CDCDCD"/>
                          <path
                              d="M10.2308 10.3758C10.2308 9.98505 10.4018 9.59434 10.7191 9.32594C11.0365 9.05735 11.427 8.95976 11.8423 9.03299C12.3795 9.13058 12.8434 9.57015 12.9167 10.1074C13.0631 10.8888 12.5505 11.4746 11.9888 11.6456C11.2074 11.8899 10.6947 12.6222 10.6947 13.4769V14.2337C10.6947 14.722 11.0854 15.1127 11.5737 15.1127C12.062 15.1127 12.4527 14.722 12.4527 14.2337V13.4769C12.4527 13.4036 12.5016 13.3304 12.5259 13.3304C14.0153 12.8421 14.9187 11.3527 14.6501 9.79006C14.4304 8.52036 13.4049 7.49488 12.1352 7.27517C11.2074 7.10418 10.2796 7.34841 9.57148 7.95883C8.86349 8.54487 8.44824 9.4239 8.44824 10.3516C8.44824 10.8399 8.83894 11.2306 9.32723 11.2306C9.84006 11.2548 10.2308 10.8641 10.2308 10.3758L10.2308 10.3758Z"
                              fill="#CDCDCD"/>
                          <path
                              d="M12.55 16.8216C12.55 17.3475 12.1237 17.7739 11.5977 17.7739C11.0718 17.7739 10.6455 17.3476 10.6455 16.8216C10.6455 16.2957 11.0718 15.8694 11.5977 15.8694C12.1237 15.8694 12.55 16.2957 12.55 16.8216Z"
                              fill="#CDCDCD"/>
                        </svg>

                        Help and Support
                      </a>
                    </div>
                    <div className='account-logout-link'>
                      <a href="#" className='text-danger'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H13.44L11.37 9.18C11.22 9.03 11.15 8.84 11.15 8.65C11.15 8.46 11.22 8.27 11.37 8.12C11.66 7.83 12.14 7.83 12.43 8.12L15.78 11.47C16.07 11.76 16.07 12.24 15.78 12.53L12.43 15.88C12.14 16.17 11.66 16.17 11.37 15.88C11.08 15.59 11.08 15.11 11.37 14.82L13.44 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
                              fill="#FF4A4A"/>
                          <path d="M2.75 11.25C2.34 11.25 2 11.59 2 12C2 12.41 2.34 12.75 2.75 12.75H9V11.25H2.75Z"
                                fill="#FF4A4A"/>
                        </svg>

                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-lg-8 col-xl-9'>
                <div className='account-right'>
                  <div className='dark-card p-3 p-sm-3 p-md-3 p-lg-5'>
                    <div className='dark-card-header'>
                      <h2 className='card-title mb-4'>Profile Information</h2>
                    </div>
                    <div className='dark-card-body'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group mb-3'>
                            <label className='form-label'>First name *</label>
                            <input className='form-control' type='text' placeholder='Enter First name'/>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-3'>
                            <label className='form-label'>last name *</label>
                            <input className='form-control' type='text' placeholder='Enter Last name'/>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group mb-3'>
                            <label className='form-label'>Your Email *</label>
                            <input className='form-control' type='email' placeholder='Enter Email'/>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group mb-3'>
                            <label className='form-label'>Mobile Number *</label>
                            <input className='form-control' type='text' placeholder='Enter Mobile Number'/>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group mb-3'>
                            <label className='form-label'>Address *</label>
                            <input className='form-control' type='text' placeholder='Enter Address'/>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group text-end mt-4'>
                            <button className='btn btn-primary py-3 px-5'>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
  )
}


export default VendorProfile