import React, { useContext, useEffect, useState } from "react";
import { url } from "../../helpers";
import { userContext } from "../../context/UserContext";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import { json, Link } from "react-router-dom";

function MyOrder() {
  const { user, vendorData, setLoad } = useContext(userContext);
  const [orders, setOrders] = useState([]);

  async function FetchCurrentCustomer() {
    setLoad(true);
    const response = await fetch(
      url +
        `resource/Customer?fields=["*"]&limit_page_length=20&limit_start=0&filters=[["custom_customer_email", "=", "${user?.message?.email}"]]`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();

      if (data?.data?.length == 0) {
        //
      } else {
        fetchOrders(JSON.stringify({ customer: data?.data[0]?.customer_name }));
      }
    } else {
      setLoad(false);
      toast.error("Internal Server Error!");
    }
  }

  async function fetchOrders(filters) {
    const response = await fetch(
      url +
        `resource/Order Progress?fields=["*"]&limit_page_length=20&limit_start=0&filters=${encodeURIComponent(
          filters
        )}`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    const data = await response.json();
    if (data?.data?.length > 0) {
      setOrders(data?.data);
    }
  }

  useEffect(() => {
    if (user?.role_profile_name == "huserprofile") {
      FetchCurrentCustomer();
    } else if (user?.role_profile_name == "Restaurant") {
      if (vendorData?.length > 0) {
        fetchOrders(
          JSON.stringify({ type: "Restaurant", doc_name: vendorData[0]?.name })
        );
      }
    } else {
      if (vendorData?.length > 0) {
        fetchOrders(
          JSON.stringify({
            type: "Qaari Zakreen",
            doc_name: vendorData[0]?.name,
          })
        );
      }
    }
  }, [vendorData]);

  async function handelStatusChange(e, resturent_name, type) {
    e.preventDefault();
    setLoad(true);

    const send_data = {
      data: {
        status:
          type == "cancel"
            ? "Cancelled"
            : type == "payment-completed"
            ? "Payment Completed"
            : "Payment Pending",
      },
    };
    const response = await fetch(
      url + `resource/Order Progress/${resturent_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: "PUT",
        body: JSON.stringify(send_data),
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Order Status Change Successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 900);
    } else {
      setLoad(false);
      toast.error("Internal Server Error!");
    }
  }
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">Profile</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="dark-card-header">
                  <h2 className="card-title mb-4">My Order</h2>
                </div>

                {orders?.length > 0 ? (
                  orders?.map((item, index) => (
                    <div key={index} className="res-dark-card mb-5">
                      <div className="res-header">
                        <div className="res-img-div">
                          {item?.package_image && <img
                            src={item?.package_image}
                            alt="img"
                            className="img-fluid"
                          />}
                          
                        </div>
                        <div className="res-name-div">
                          <h2>{item?.packagename}</h2>
                          <p>{item?.package_description}</p>
                        </div>
                      </div>
                      <div className="res-dark-body">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Date</label>
                              <h6>{item?.creation?.split(" ")[0]}</h6>
                            </div>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Units</label>
                              <h6>{item?.package_minimum_unit}</h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Location</label>
                              <h6>{item?.shipping_address}</h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Unit Price</label>
                              <h6>
                                {parseFloat(item?.package_unit_price).toFixed(
                                  2
                                )}{" "}
                                KD
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>total price</label>
                              <h6>
                                {(
                                  parseFloat(item?.package_unit_price) *
                                  parseFloat(item?.package_minimum_unit)
                                ).toFixed(2)}{" "}
                                KD
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Status</label>
                              <h6
                                className={
                                  item?.status == "Payment Completed" ||
                                  item?.status == "Delivered"
                                    ? "text-success"
                                    : item?.status == "Cancelled"
                                    ? "text-danger"
                                    : "text-warning"
                                }
                              >
                                {item?.status}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="res-dark-footer text-end">
                        {user?.role_profile_name == "Restaurant" ||
                        user?.role_profile_name == "Qaari" ? ( 
                          item?.status == "Pending Verification" ? (
                          <>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                handelStatusChange(e, item?.name, "cancel");
                              }}
                            >
                              Reject
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                handelStatusChange(e, item?.name, "approved");
                              }}
                            >
                              Approved
                            </button>
                          </>

                          ):(
                            null
                          )
                        ) : item?.status == "Pending Verification" ? (
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              handelStatusChange(e, item?.name, "cancel");
                            }}
                          >
                            Cancel
                          </button>
                        ) : item?.status == "Service Unavailable" ? (
                          <>
                            <button
                              className="btn btn-danger"
                              type="submit"
                              onClick={(e) => {
                                handelStatusChange(e, item?.name, "cancel");
                              }}
                            >
                              Cancel
                            </button>
                          </>
                        ) : item?.status == "Payment Pending" ? (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              handelStatusChange(
                                e,
                                item?.name,
                                "payment-completed"
                              );
                            }}
                          >
                            Pay
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p className="text-center">No Order Found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrder;
